<template>
  <b-card
    img-alt="Card image cap"
    img-top
    class="p-0"
    no-body
  >
    <b-card-img
      :src="course.image"
      class="card-image-size"
    />
    <b-card-body>
      <!-- Pricing -->
      <div class="d-flex flex-column mb-1">
        <span class="price">Rp. {{ formatPrice(course.price) }}</span>
        <span class="price-strike">Rp. {{ formatPrice(course.base_price) }}</span>
      </div>

      <!-- Detail Course -->
      <div>
        <b-row
          v-for="(detail, index) in course.detail"
          :key="index"
        >
          <b-col
            cols="4"
            md="4"
            lg="5"
          >
            <i
              class="ti text-secondary"
              :class="detail.icon"
            />
            <span class="ml-1 font-weight-bolder">{{ detail.title }}</span>
          </b-col>
          <b-col
            cols="8"
            md="8"
            lg="7"
          >
            <span> : {{ detail.value }} </span>
          </b-col>
        </b-row>
      </div>

      <div class="d-flex justify-content-center mt-1">
        <join-course
          :id="course.id"
          :title="course.title"
          :join-status="joinStatus"
        >
          <slot />
        </join-course>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardImg,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import JoinCourse from './JoinCourse.vue'

export default {
  components: {
    BCard,
    BCardImg,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    JoinCourse,
  },
  directives: {
    Ripple,
  },
  props: {
    course: {
      type: Object,
      default: () => ({
        id: '',
        title: 'Kelas AR (Augmented Reality)',
        desc:
          'Kelas AR (Augmented Reality) adalah kelas yang membahas tentang penggunaan teknologi AR dalam pembelajaran. Dalam kelas ini, peserta akan belajar bagaimana cara menggunakan teknologi AR dalam pembelajaran.',
        image: require('@/assets/images/slider/06.jpg'),
        price: 150000,
        base_price: 200000,
        detail: [
          {
            title: 'Rating',
            value: '4,5 / 5',
            unit: 'rating',
            icon: 'ti-star',
          },
          {
            title: 'Peserta',
            value: '100',
            unit: 'peserta',
            icon: 'ti-users',
          },
          {
            title: 'Waktu',
            value: '10',
            unit: 'minggu',
            icon: 'ti-calendar',
          },
          {
            title: 'Kategori',
            value: 'Augmented Reality',
            icon: 'ti-list',
          },
          {
            title: 'Level',
            value: 'Basic',
            icon: 'ti-trending-up',
          },
          {
            title: 'Akses',
            value: 'Lifetime',
            icon: 'ti-key',
          },
        ],
      }),
    },
    joinStatus: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    formatPrice(price) {
      if (isNaN(price)) {
        price = parseInt(price)
      }
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
  },
}
</script>

<style lang="scss" scoped>
$secondary: #fb6514;

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.price {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: $secondary;
}

.price-strike {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: line-through;
}

.bg-secondary {
  background-color: $secondary !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.card-img-top {
  object-fit: cover;
  height: 250px;
}

.card-image-size {
  aspect-ratio: 16 / 9;
}
</style>
