<template>
  <div
    v-if="training.id"
    class="program-container"
  >
    <!-- Hero -->
    <hero
      :id="training.id"
      :breadcrumb-items="items"
      :title="training.title"
      :join-status="joinStatus"
    />

    <!-- Navtab -->
    <section class="px-1 px-sm-2 px-md-4">
      <b-tabs
        pills
        class="navigation-tabs my-2"
        nav-class="mb-0"
      >
        <template #tabs-start>
          <b-nav-item
            v-for="(navtab, index) in navTabs"
            :key="index"
            :active="currentTab === index"
            class="font-weight-bold"
            @click="currentTab = index"
          >
            <span>{{ navtab }}</span>
          </b-nav-item>
        </template>
      </b-tabs>
    </section>

    <!-- Section Info -->
    <section class="mb-2 px-1 px-sm-2 px-md-4">
      <b-row>
        <b-col lg="8">
          <!-- SECTION : INFO -->
          <section v-if="currentTab === 0">
            <!-- Basic Information -->
            <h2 class="title">
              Tentang {{ training.title }}
            </h2>
            <p class="py-1 pr-1 clean-paragraph ">
              {{ training.description }}
            </p>

            <!-- Material Lists -->
            <h2 class="title mb-1">
              Keterampilan teknis:
            </h2>
            <ul class="card p-2 list-unstyled bg-transparent ">
              <li
                v-for="(skill, index) in training.training_technical_skills"
                :key="index"
                class="d-flex align-items-center list-padding"
              >
                <img
                  src="@/assets/tekov/icons/check-icon.svg"
                  alt=""
                  srcset=""
                >
                <span class="ml-1">{{ skill.name }}</span>
              </li>
            </ul>
          </section>

          <!-- SECTION : MATERIAL LIST  -->
          <!-- <section v-if="currentTab === 1">
            <h2 class="title">
              Silabus Materi
            </h2>
            <material-list :list="materialList" />
          </section> -->

          <!-- SECTION : REVIEW  -->
          <section v-if="currentTab === 1">
            <h2 class="title">
              Review
            </h2>

            <div class="mt-2">
              <div
                v-for="(item, index) in training.training_reviews"
                :key="index"
                class="card p-2 mb-1 bg-transparent "
              >
                <div class="d-flex">
                  <b-avatar
                    size="42"
                    :src="item.user.avatar"
                    class="badge-minimal mr-1"
                    badge-variant="primary"
                    variant="transparent"
                  />
                  <div class="d-flex flex-column">
                    <h5 class="mb-0">
                      {{ item.user.name }}
                    </h5>
                    <span class="pb-1">
                      {{ item.user.email }}
                    </span>
                    <p class="card-text">
                      {{ item.review }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="!training.training_reviews.length"
              class="mt-2"
            >
              <div class="card p-2 mb-1 bg-transparent ">
                <div class="d-flex">
                  Belum ada review
                </div>
              </div>
            </div>
          </section>
        </b-col>

        <b-col lg="4">
          <course-card
            :course="trainingToCourse"
            :join-status="joinStatus"
            class="offset-mt"
          />
        </b-col>
      </b-row>
    </section>

    <!-- Explore -->
    <explore />

    <!-- Footer -->
    <custom-footer />
  </div>
</template>

<script>
import {
  BNavItem, BRow, BCol, BTabs, BAvatar,
} from 'bootstrap-vue'

// Custom Components
import Explore from '@/views/tekov/landingpage/components/Explore.vue'
import CustomFooter from '@/views/tekov/landingpage/components/Footer.vue'
// eslint-disable-next-line import/no-cycle
import { reqGetTraining } from '@/api/guest/training'
import { reqGetTraining as reqGetTrainingLogin } from '@/api/user/training'
import {
  isUserLoggedIn,
} from '@/auth/utils'
import CourseCard from './components/CourseCard.vue'
import Hero from './components/Hero.vue'

export default {
  components: {
    BNavItem,
    BRow,
    BCol,
    BTabs,
    BAvatar,
    Hero,
    CourseCard,
    Explore,
    CustomFooter,
  },
  data() {
    return {
      items: [
        {
          text: 'Home',
          to: '/',
        },
        {
          text: 'Kelas',
          active: true,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
      levels: ['undefined', 'Basic', 'Advance', 'Intermediate'],
      materialList: [
        {
          title: 'Pengenalan Augmented Reality',
          contents: [
            'Pengertian Augmented Reality',
            'Sejarah Augmented Reality',
            'Cara Kerja Augmented Reality',
            'Aplikasi Augmented Reality',
            'Kelebihan dan Kekurangan Augmented Reality',
          ],
          time: '1 Jam',
          tags: [
            {
              text: 'Augmented Reality',
              variant: 'primary',
            },
            {
              text: 'Pengertian',
              variant: 'secondary',
            },
            {
              text: 'Sejarah',
              variant: 'success',
            },
          ],
        },
        {
          title: 'Pengenalan Augmented Reality',
          contents: [
            'Pengertian Augmented Reality',
            'Sejarah Augmented Reality',
            'Cara Kerja Augmented Reality',
            'Aplikasi Augmented Reality',
            'Kelebihan dan Kekurangan Augmented Reality',
          ],
          time: '1 Jam',
          tags: [
            {
              text: 'Augmented Reality',
              variant: 'primary',
            },
            {
              text: 'Pengertian',
              variant: 'secondary',
            },
            {
              text: 'Sejarah',
              variant: 'success',
            },
          ],
        },
      ],
      training: {},

      currentTab: 0,
      navTabs: ['Informasi', 'Review'],
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    joinStatus() {
      if (!this.training.join_status) {
        return {
          value: 0,
          text: 'Gabung Sekarang',
          color: 'primary',
          disabled: false,
        }
      // eslint-disable-next-line eqeqeq
      } if (this.training.join_status.status == 'Diterima') {
        return {
          value: 1,
          text: 'Masuk',
          color: 'success',
          disabled: false,
        }
      // eslint-disable-next-line eqeqeq
      }
      return {
        value: 2,
        text: this.training.join_status.status,
        color: 'secondary',
        disabled: true,
      }
    },
    trainingToCourse() {
      return {
        id: this.training.id,
        title: this.training.title,
        image: this.training.image,
        price: (this.training.price * (100 - this.training.discount)) / 100,
        base_price: this.training.price,
        detail: [
          {
            title: 'Rating',
            value: this.training.training_reviews.length ? this.training.training_reviews.reduce((total, obj) => total + obj.rating, 0) / this.training.training_reviews.length : '-' + ('/5'),
            unit: 'rating',
            icon: 'ti-star',
          },
          /* {
            title: 'Peserta',
            value: '100',
            unit: 'peserta',
            icon: 'ti-users',
          }, */
          {
            title: 'Tanggal',
            value: this.$moment(this.training.start_at).format('DD/MM/YYYY'),
            unit: 'minggu',
            icon: 'ti-calendar',
          },
          /* {
            title: 'Kategori',
            value: 'Augmented Reality',
            icon: 'ti-list',
          }, */
          {
            title: 'Level',
            value: this.levels[this.training.level],
            icon: 'ti-trending-up',
          },
          /* {
            title: 'Akses',
            value: 'Lifetime',
            icon: 'ti-key',
          }, */
        ],
      }
    },
  },
  mounted() {
    this.getTraining()
  },
  methods: {
    isUserLoggedIn,
    getTraining() {
      let API = reqGetTraining
      if (isUserLoggedIn()) {
        API = reqGetTrainingLogin
      }
      API(this.$route.params.trainingId).then(res => {
        this.training = res.data.data
        this.items[1].text = this.training.title
      })
    },
  },
}
</script>

<style lang="scss">
.program-container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100px !important;
}

.list-padding {
  padding: 0.4rem 0;
}

@media only screen and (min-width: 1000) {
  .offset-mt {
    margin-top: -18rem;
  }
}

.clean-paragraph {
  text-justify: inter-word;
  line-height: 26px;
}
</style>
