<template>
  <section @click="joinTraining(id)">
    <slot>
      <b-button
        class="my-1"
        :variant="joinStatus.color"
        :disabled="joinStatus.disabled"
      >
        <i class="ti ti-arrow-right" />
        {{ joinStatus.text }}
      </b-button>
    </slot>
  </section>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import { reqJoinTraining } from '@/api/user/training'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    id: {
      type: Number,
      default: () => 0,
    },
    joinStatus: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    joinTraining() {
      this.$router.push({
        name: 'user-trainings.show',
        params: {
          id: this.id,
        },
      })
    },
  },
}
</script>
